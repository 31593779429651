<script>
  import { createEventDispatcher } from 'svelte';
  import Pagination from "../Pagination/Pagination.svelte";

  const dispatch = createEventDispatcher();

  // can be one of light or dark
  export let color = "light";
  export let columns = []
  export let results = []
  export let title = '查詢結果'

  let paginatedResults = []
  let pageSize = 10
  let currentPage = 1

  $:{
    let count = (currentPage - 1) * pageSize
    if(results.length > pageSize) {
      paginatedResults = results.slice(count, count + pageSize)
    } else {
      paginatedResults = results
    }
  }

  function selectPage(page) {
    currentPage = page
  }

  function deleteItem(id) {
    dispatch('delete', id)
  }

  function addItem() {
    dispatch('add')
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-blueGray-800 text-white'}"
>
  {#if title !== ''}
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{title} 
          <small> 共 {results.length} 筆</small>
        </h3>
      </div>
    </div>
   
  </div>
  {/if}
  
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          {#each columns as item}
            <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold 
              text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-blueGray-700 text-blueGray-200 border-blueGray-600'}" >
              {(item === '__VIEW__' || item === '__DELETE__' || item === '__EDIT__'|| item === '__EDIT_ADD__' || item === '__VIEW_ADD__' 
                || item === '__IMAGE__'|| item === '__EXPORT__'? '' : item)}
              {#if item === '__EDIT_ADD__' || item === '__VIEW_ADD__'}
                <button on:click={addItem}
                  class="bg-blueGray-600 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                  <i class="fas fa-plus" ></i> 新增
                </button>
              {/if}
            </th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each paginatedResults as result}
          <tr>
            {#each  result as item, i}
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {#if (columns[i] === '__VIEW__'|| columns[i] === '__VIEW_ADD__') && item !== ''}
                  <button class="bg-blueGray-600 text-white active:bg-blueGray-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                      type="button" on:click={()=> location.href = item }>
                    <i class="fas fa-search-plus"></i> 查看
                  </button>
                {:else if (columns[i] === '__EXPORT__')}
                  <button class="bg-blueGray-600 text-white active:bg-blueGray-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                      type="button" on:click={()=> location.href = item }>
                    <i class="fas fa-download"></i> 下載
                  </button>
                {:else if (columns[i] === '__EDIT__' || columns[i] === '__EDIT_ADD__')}
                <button class="bg-blueGray-600 text-white active:bg-blueGray-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    type="button" on:click={()=> location.href = item }>
                  <i class="fas fa-paint-brush"></i> 修改
                </button>
                {:else if ((columns[i] === '__DELETE__') && item !== '')}
                <button class="bg-orange-500 text-white active:bg-orange-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    type="button" on:click={()=> deleteItem(item) }>
                  <i class="fas fa-trash"></i> 刪除
                </button>
                {:else if ((columns[i] === '__IMAGE__') && item !== '')}
                <img src={item} class="h-12 w-12 bg-white rounded-full border" alt="...">
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-{item.split('#')[1]}-600 bg-{item.split('#')[1]}-200 uppercase last:mr-0 mr-1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                {:else}
                  {#if (typeof item === 'string' && item.indexOf('#') >= 0)}
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-{item.split('#')[1]}-600 bg-{item.split('#')[1]}-200 uppercase last:mr-0 mr-1">
                      {item.split('#')[0]}
                    </span>
                  {:else}
                   {(item === null ? '' : item)} 
                  {/if}
                {/if}
              </td>
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
  <Pagination items={results} {currentPage} {pageSize} on:select={e => selectPage(e.detail)}/>
</div>

