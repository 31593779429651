<script>
	import { onMount } from 'svelte';
	import { apiConfig, viewInfo, checkRole, userInfo } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte";

	$viewInfo.funcName = '還杯紀錄查詢'

	let color = 'light'
	let columns = ['會員編號', '平台別', '訂單編號' , '還杯方式', '數量','店號', '還杯時間']
    let results = []
	
	const urlParams = new URLSearchParams(window.location.search)
	let returnType = ''
	let platform = checkRole($userInfo.role,'sbk_user') ? '星巴克' : ''
	let gid = ''
	let sdate = new Date().toLocaleDateString()
	let edate = new Date().toLocaleDateString()	

	/**
	 * 查詢門市
	 */
	function query() {
		var url = `${$apiConfig.apiUrl}/report/returnCup?code=${$apiConfig.apiKey}&sdate=${sdate}&edate=${edate}&gid=${gid}`
		
		fetch(url , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					results = []

					d.info = platform === 'iECO' ?
						 d.info.filter(el=> (el.provider === 0)) : platform === '星巴克' ? 
						 d.info.filter(el=> (el.provider === 1)) : d.info

					d.info.forEach(function(v, i, arr) {
						// console.log(v)
						v.returnInfo.forEach(function(value,index,array) {
							// console.log('value=', v)
							if(returnType === '還杯機' && array[index].returnType !== 0) { return false }
							if(returnType === '人工結算歸還' && array[index].returnType !== 1) { return false }
							if(returnType === '超商櫃台' && array[index].returnType !== 2) { return false }
							if(returnType === '星巴克櫃台' && array[index].returnType !== 3) { return false }


							let result = []

							result.push(arr[i].gid)
							result.push(arr[i].provider === 0 ? 'iECO#emerald' : arr[i].provider === 1 ? '星巴克#teal' : '其他通路#blueGray')
							result.push(array[index].id)
							result.push(array[index].returnType === 0  ? '還杯機#teal' 
								: array[index].returnType === 1  ? '人工結算歸還#red' 
								: array[index].returnType === 2  ? '超商櫃台#orange' 
								: array[index].returnType === 3  ? '星巴克櫃台#emerald' : '其他通路櫃台#blueGray')
							result.push(array[index].count)
							result.push(array[index].storeId)
							result.push(array[index].returnTime)

							results.push(result)
						})
					});

					results = results
				} 
		})
		.catch(e => {
			console.log(e)
		})
	}

	query()
	
</script>

<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">查詢條件</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={query}
					>  <i class="fas fa-search" ></i> 
						查詢
					</button>
				</div>
			</div>
			<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
				<form>
					<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
						選擇查詢方式
					</h6>
					<div class="flex flex-wrap">
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="sdate">
								開始日期
								</label>
								<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									id="sdate" type="date" bind:value={sdate} />
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="edate">
									結束日期
								</label>
								<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									id="edate" type="date" bind:value={edate}  />
							</div>
						</div>

						{#if checkRole($userInfo.role, 'sys_admin')}
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									平台別
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={platform} on:change="{query}">
									<option></option>
									<option value="iECO">iECO</option>
									<option value="星巴克">星巴克</option>
								</select>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									還杯方式
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={returnType} on:change="{query}">
									<option></option>
									<option value="還杯機">還杯機</option>
									<option value="人工結算歸還">人工結算歸還</option>
									<option value="超商櫃台">超商櫃台</option>
									<option value="星巴克櫃台">星巴克櫃台</option>
								</select>
							</div>
						</div>
						{/if}

						<div class="w-full lg:w-6/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-name">
									會員ID
								</label>
								<input id="grid-address" type="text"
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={gid}/>
							</div>
						</div>
					</div>
				</form>
			</div>
			</div>
	  </div>
	<div class="w-full mb-12 px-4">
		<ResultTable {color} {columns} {results}/>
	</div>
</div>