<script>
    import { onMount } from 'svelte';
	import { apiConfig, userInfo } from '../../store/admin-store' 

	export let account = { id: $userInfo.id, secret: '', key: '', key2: '' } 

	let showSecret = false
	let showKey1 = false
	let showKey2 = false

	let isNullSecret = false
	let isWrongSecret = false
	let isNullKey1 = false
	let isNullKey2 = false
	let areNotEqualKeys = false
	let isKeyStrengthStrong = false
	let isKeyStrengthMedium = false

	// $:console.log('account', account)
	// $:console.log('userInfo', $userInfo)

	/**
	 * 重設密碼
	 */
	function save() {
		isNullSecret = account.secret === ''
		isNullKey1 = account.key === ''
		isNullKey2 = account.key2 === ''
		areNotEqualKeys = account.key !== account.key2

		if(isNullSecret || isNullKey1 || isNullKey2 || areNotEqualKeys || !isKeyStrengthMedium) { return false }

		let param = account
		fetch(`${$apiConfig.apiUrl}/account/reset?code=${$apiConfig.apiKey}`, {
			method: 'POST',
			body:JSON.stringify(param)
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				$userInfo.hasKey = true
				$userInfo.isExpired = false
				alert('修改成功！')
				window.location.href = '/admin/dashboard'
			} else {
				isWrongSecret = true
			}
		})
	}

	function validateStrength() {
		var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    	var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
		isKeyStrengthStrong = strongRegex.test(account.key)
		isKeyStrengthMedium = mediumRegex.test(account.key)
	}

</script>

{#if (account !== undefined)}
  <div class="flex flex-wrap">
	<div class="w-full lg:w-12/12 px-4">
		<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	  >
		<div class="rounded-t bg-white mb-0 px-6 py-6">
		  <div class="text-center flex justify-between">
			<h6 class="text-blueGray-700 text-xl font-bold">重設密碼</h6>
			<button
				class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
				type="button" on:click={save}
			><i class="fas fa-save"></i>&nbsp;儲存
			</button>
		  </div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
		  <form>
			<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
			</h6>
			<div class="flex flex-wrap">
			  <div class="w-full lg:w-8/12 px-4">
				 <div class="relative w-full mb-3">

					{#if $userInfo.isExpired}
					<div class="text-red-400 mb-3 font-bold">
						<small>您的帳號超過三個月尚未進行密碼變更，請立即重設密碼</small>
					</div>
					{/if}

					<label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-secret"
					>
						舊密碼
					</label>
					{#if showSecret}
					<input
						id="grid-secret"
						type="text" 
						class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						bind:value={account.secret}
					/>	
					{:else}
					<input
						id="grid-secret"
						type="password" 
						class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						bind:value={account.secret}
					/>			
					{/if}
					<span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
					   <i class="fas fa-eye{showSecret ? '' : '-slash'}" on:click={()=> showSecret = !showSecret}></i>
					</span>

					{#if isNullSecret}
					<div class="text-red-400 mb-3 font-bold">
						<small>舊密碼不得空白</small>
					</div>
					{/if}
					{#if isWrongSecret}
					<div class="text-red-400 mb-3 font-bold">
						<small>密碼錯誤，請重新輸入</small>
					</div>
					{/if}
				</div>
			  </div>
			  <div class="w-full lg:w-8/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-key"
				  >
				  新密碼
				  </label>
				  {#if showKey1}
				  <input
					  id="grid-key"
					  type="text" 
					  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					  on:change={validateStrength} 
					  bind:value={account.key}
				  />	
				  {:else}
				  <input
					  id="grid-key"
					  type="password" 
					  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					  on:keyup={validateStrength}  
					  bind:value={account.key}
				  />			
				  {/if}
				  <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
					 <i class="fas fa-eye{showKey1 ? '' : '-slash'}" on:click={()=> showKey1 = !showKey1}></i>
				  </span>

				  {#if isNullKey1}
				  <div class="text-red-400 mb-3 font-bold">
					<small>密碼不得空白</small>
				  </div>
				  {/if}

				  {#if account.key !== ''}
					{#if isKeyStrengthStrong}
					<div class="text-emerald-500 mb-3 font-bold">
						<small>密碼強度：高</small>
					</div>
					{:else if isKeyStrengthMedium}
					<div class="text-orange-500 mb-3 font-bold">
						<small>密碼強度：中等</small>
					</div>
					{:else}
					<div class="text-red-400 mb-3 font-bold">
						<small>密碼強度：低</small>
					</div>
					{/if}
				  {/if}
				</div>
			  </div>

			  <div class="w-full lg:w-8/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-key2"
				  >
				  新密碼確認
				  </label>
				  {#if showKey2}
				  <input
					  id="grid-key2"
					  type="text" 
					  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					  bind:value={account.key2}
				  />	
				  {:else}
				  <input
					  id="grid-key2"
					  type="password" 
					  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					  bind:value={account.key2}
				  />			
				  {/if}
				  <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
					 <i class="fas fa-eye{showKey2 ? '' : '-slash'}" on:click={()=> showKey2 = !showKey2}></i>
				  </span>

				{#if isNullKey2}
				  <div class="text-red-400 mb-3 font-bold">
					<small>密碼不得空白</small>
				  </div>
				{:else if areNotEqualKeys}
				  <div class="text-red-400 mb-3 font-bold">
					<small>新密碼必須與前一次輸入相同</small>
				  </div>
				{/if}
				</div>
			  </div>

			</div>
		  </form>
		</div>
	  </div>
	</div>
  </div>

{/if}