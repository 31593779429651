<script>
	import { onMount } from 'svelte';
	import { apiConfig, viewInfo } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte"
    import BusinessUnitSelector from './BusinessUnitSelector.svelte'

	$viewInfo.funcName = '通路管理'

	let color = 'light'
	let columns = ['通路別名稱', '服務提供者', '是否啟用', '更新時間','__EDIT_ADD__']
    let results = []
	
	const urlParams = new URLSearchParams(window.location.search)
    let bu = urlParams.get('bu') ?? ''
	
	let allBu = []
	let provider = ''

	// $:console.log(provider)

	onMount(()=> {
		fetch(`${$apiConfig.apiUrl}/bu/list?code=${$apiConfig.apiKey}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					allBu = d.info					
				} 
			})
			.catch(e => {
				console.log(e)
			})
	})

	/**
	 * 查詢通路
	 */
	function query() {

		var url = `${$apiConfig.apiUrl}/bu/list?code=${$apiConfig.apiKey}&bu=${bu}`

		fetch(url , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					results = []

					var list = provider === '' ? d.info : d.info.filter(el=> (el.provider === parseInt(provider)))

					list.forEach(function(value, index, array){
						let result = []
						result.push(array[index].name)
					    result.push(array[index].provider === 0 ? '7-11超商iECO#orange' :array[index].provider === 1 ? '星巴克#teal' : '統一相關企業通路#red')
						result.push(array[index].isAlive ? '啟用#emerald' : '停用#blueGray')
						result.push(array[index].updateTime)
						result.push(`/admin/bu-info/${array[index].id}`)

						results.push(result)
					});

					results = results
				} 
		})
		.catch(e => {
			console.log(e)
		})
	}

	/**
	 * 新增通路
	 */
	function add() {
		location.href = '/admin/bu-info-new'
	}
	
	/**
	 * 根據BusinessUnitSelector所回傳BU重新查詢
	 * @param item
	 */
	function onSelectBU(item) {
        console.log('item changed', item);
		bu = item
		query()
	}
	
</script>

<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">查詢條件</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={query}
					>  <i class="fas fa-search" ></i> 
						查詢
					</button>
				</div>
			</div>
			<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
				<form>
					<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
						選擇查詢方式
					</h6>
					<div class="flex flex-wrap">
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									通路別
								</label>
								<BusinessUnitSelector on:select={e => onSelectBU(e.detail)}></BusinessUnitSelector>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									服務提供者
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={provider} on:change="{query}">
									<option value=""></option>
									<option value="0">7-11超商iECO</option>
									<option value="1">星巴克</option>
									<option value="2">統一相關企業通路</option>
								</select>
							</div>
						</div>
					</div>
				</form>
			</div>
			</div>
	  </div>
	<div class="w-full mb-12 px-4">
		<ResultTable {color} {columns} {results} on:add={e => add()}/>
	</div>
</div>