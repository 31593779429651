<script>
	export let order
	
	let showReturnDetail = true

</script>
<hr class="mt-6 border-b-1 border-blueGray-300" />

<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
	<i class="fa fa-{showReturnDetail ? 'minus' : 'plus'}" on:click={()=> showReturnDetail = !showReturnDetail}></i>
	還杯歷程紀錄
</h6>

{#if showReturnDetail}
	{#each order.returnInfo as item}
		<div class="flex flex-wrap">

			<div class="w-full mb-3">
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-emerald-600 bg-emerald-200 last:mr-0 mr-1">
				{(item.returnType === 0 ? '還杯機' : item.returnType === 1 ? '人工結算歸還' : item.returnType === 2 ? '超商櫃台還杯' : 
				item.returnType === 3 ? '星巴克櫃台還杯': '跨BU櫃台還杯')} 
				</span>
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-amber-600 bg-amber-200 last:mr-0 mr-1">
					{item.count} 杯 
				</span>
			</div>

			<div class="w-8/12 mb-3">	
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					歸還時間： {item.returnTime} 
				</label>
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					店號： {item.storeId} 
				</label>	
			</div>
		</div>
	{/each}
{/if}