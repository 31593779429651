<script>
	import { onMount } from 'svelte';
	import { apiConfig, viewInfo } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte"

	$viewInfo.funcName = '門市資料檔'

	let color = 'light' 
	let columns = ['期別', '資料更新日期',  '__EXPORT__']
    let results = []
	
	onMount(()=> {
		
		fetch(`${$apiConfig.apiUrl}/stock/storeFile?code=${$apiConfig.apiKey}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					results = []

					d.info.sort(function(a, b) {
						var nameA = a.name.toUpperCase()
						var nameB = b.name.toUpperCase()
						if (nameA < nameB) { return 1 }
						if (nameA > nameB) { return -1 }
						return 0
					});

					d.info.forEach(function(value, index, array){
						let result = []
						result.push(array[index].name)
						result.push(formatDate(array[index].name.split('(')[1].split(')')[0]))
						result.push(array[index].url)
						results.push(result)
					});

					results = results
				} 
		})
		.catch(e => {
			console.log(e)
		})
	})

	function formatDate(inputDate) {
		var year = inputDate.substring(0, 4);
		var month = inputDate.substring(4, 6);
		var day = inputDate.substring(6, 8);
		var date = new Date(year, month - 1, day); 

		var formattedDate = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
		
		return formattedDate;
	}
</script>

<div class="flex flex-wrap mt-4">
	<div class="w-full mb-12 px-4">
		<ResultTable {color} {columns} {results}/>
	</div>
</div>