<script>
    import API from '../../../src/store/config';
    import { apiConfig } from '../../../src/store/admin-store' 

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const appMode = urlParams.get('mode')
    if(appMode === 'dev') {
        $apiConfig.storageEndpoint = 'https://recyclecupteststorage.blob.core.windows.net'
    } else if($apiConfig.storageEndpoint === ''){
        fetch(`${API}/apiUrl`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(d => {
            // console.log(d)
            $apiConfig = d
            appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`
            location.reload()
        });
    }

    $:console.log('apiConfig', $apiConfig)

</script>