<script>
    import { onMount } from 'svelte';
	import { apiConfig, viewInfo } from '../../store/admin-store' 
	import QRCode from "components/Cards/QRCode.svelte";

	export let bu
	export let id
	
	let store
	let buTradeRule = { borrowPincode: '', returnPincode: ''}

	let results = []

	// $:console.log(store)

	onMount(()=> {
		fetch(`${$apiConfig.apiUrl}/store/list?code=${$apiConfig.apiKey}&id=${id}&bu=${bu}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					store = d.info[0]
					buTradeRule = store.buTradeRule.filter(el=> el.isAlive)[0]
					
				} 
			})
			.catch(e => {
				console.log(e)
			})
	})
	
	$:console.log('id', id)

	let openTab = 1;

	function toggleTabs(tabNumber){
		openTab = tabNumber
	}

	function getPrintPincode(){
		var urlSchema = 'https://openpointapp.page.link/6fSx?id='

		fetch(`${$apiConfig.apiUrl}/store/pincode?code=${$apiConfig.apiKey}&key1=${urlSchema}${buTradeRule.borrowKey}&key2=${buTradeRule.returnKey}&id=${store.id}&name=${store.name}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					location.href = d.info.url
				} 
			})
			.catch(e => {
				console.log(e)
			})

	}
</script>

{#if (store !== undefined)}
<div class="flex flex-wrap">
  <div class="w-full lg:w-12/12 px-4">
	  <div
	  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	>
	  <div class="rounded-t bg-white mb-0 px-6 py-6">
		<div class="text-center flex justify-between">
		  <h6 class="text-blueGray-700 text-xl font-bold">門市服務識別碼</h6>
		  <button
				class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
				type="button" on:click={getPrintPincode}
			> <i class="fas fa-download" ></i> 
				取得上傳列印圖檔
			</button>
		</div>
	  </div>
	  <div class="flex-auto px-4 lg:px-10 py-10 pt-3">
		<div class="w-full">
			<ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
			  <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
				<btn class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab === 1 ? 'text-white bg-blueGray-600':'text-blueGray-600 bg-white'}" on:click={() => toggleTabs(1)}>
				  <i class="fas fa-glass-whiskey text-base mr-1"></i> 兌換取杯
				</btn>
			  </li>
			  <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
				<btn class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab === 2 ? 'text-white bg-blueGray-600':'text-blueGray-600 bg-white'}" on:click={() => toggleTabs(2)}>
				  <i class="fas fa-undo text-base mr-1"></i> 還杯
				</btn>
			  </li>
			</ul>
			<div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
			  <div class="px-4 py-5 flex-auto text-center ">
				<div class="tab-content tab-space">
				  <div class="{openTab === 1 ? 'block':'hidden'}">

					<div class="w-full px-4 py-5 flex justify-center">
						<div class="relative">
							<QRCode text={buTradeRule.borrowKey} id={'borrowPincode'} qrbox=165/>
						</div>
					</div>
					<small>
						{store.name} {store.id}
					</small>
				  </div>
				  <div class="{openTab === 2 ? 'block':'hidden'}">
					<div class="w-full px-4 py-5 flex justify-center">
						<div class="relative">
							<QRCode text={buTradeRule.returnKey} id={'returnPincode'} qrbox=165/>
						</div>
					</div>
					<small>
						{store.name} {store.id}
					</small>
				  </div>
				</div>
			  </div>
			</div>
		  </div>

		</div>
	</div>
	
  </div>
</div>

{/if}