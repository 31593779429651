<script>
    import { onMount } from 'svelte';
	import { apiConfig } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte"
	import BusinessUnitSelector from './BusinessUnitSelector.svelte'
	import StoreCitySelector from './StoreCitySelector.svelte'
	
	export let bu
	export let id
	
	let store = { id: '', name: '', bu: -1, city: '', area: '', address: '', tel: '', zone: '',
			districtType: '', hasMachine: false, hasPOS: false, businessUnitName: '', isAlive: false, 
			startTime: new Date().toLocaleDateString(), endTime: new Date().toLocaleDateString()
		}

	let mode = location.href.indexOf('store-qrcode') >= 0 ? 'QRCODE' : 'STORE'

	let isBUreadonly = false
	let display711 = false

	let isNullId = false
	let isNullName = false
	let isNullBU = false
	let isNullCity = false
	let isNullArea = false
	let isNullAddress = false
	let isNullTel = false
	

	let results = []
	let color = 'light'
	let columns = ['','借杯識別碼', '還杯識別碼', '到期時間', '__VIEW_ADD__' ]

	$:console.log(store)

	onMount(()=> {

		if(id === '' || id === undefined) { return false }

		fetch(`${$apiConfig.apiUrl}/store/list?code=${$apiConfig.apiKey}&id=${id}&bu=${bu}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					isBUreadonly = true
					display711 = true

					store = d.info[0]
					store.startTime = store.startTime.split('T')[0]
					store.endTime = store.endTime.split('T')[0]

					results = []

					store.buTradeRule.reverse().forEach(function(value, index, array){
						let result = []
						result.push(array[index].isAlive ?'可使用#emerald' : '已逾期#blueGray')
						result.push(array[index].borrowKey)
						result.push(array[index].returnKey)
						result.push(array[index].expiredTime)
						result.push(array[index].isAlive ? `/admin/store-qrcode-preview/${store.bu}/${store.id}` : '')
						results.push(result)
					});

					results = results

				} 
			})
			.catch(e => {
				console.log(e)
			})
	})

	/**
	 * 儲存變更門市資訊
	 */
	 function save() {
		check()
		if(isNullId || isNullName || isNullBU || isNullCity || isNullArea || isNullAddress || isNullTel) { return false }

		let param = store
		fetch(`${$apiConfig.apiUrl}/store?code=${$apiConfig.apiKey}`, {
			method: 'POST',
			body:JSON.stringify(param)
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				window.location.href = '/admin/store-info'
			} else {
				alert(d.returnMessage)
			}
		})
	}

	function check() {
		isNullId = store.id === ''
		isNullName = store.name === ''
		isNullBU = store.bu < 0 || store.bu === ''
		isNullCity = store.city === ''
		isNullArea = store.area === ''
		isNullAddress = store.address === ''
		isNullTel = store.tel === ''
	}

	/**
	 * 重置服務識別碼
	 */
	 function resetSecret() {
		let param = store
		fetch(`${$apiConfig.apiUrl}/store/reset?code=${$apiConfig.apiKey}`, {
			method: 'POST',
			body:JSON.stringify(param)
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				location.href = location.href
			} 
		})
	}

	function add() {
		if( mode === 'QRCODE' && confirm("即將進行重置服務識別碼，確定要繼續？") === true) {
			resetSecret()
		}
	}

	/**
	 * 根據BusinessUnitSelector所回傳BU重新賦值
	 * @param item
	 */
	 function onSelectBU(item) {
        console.log('item changed', item);
		store.bu = item
	}

	/**
	 * 根據BusinessUnitSelector所回傳縣市別重新賦值
	 * @param item
	 */
	 function onSelectCity(item) {
        console.log('item changed', item);
		store.city = item
	}
	
	$:console.log('id', id)

</script>

{#if (store !== undefined)}
  <div class="flex flex-wrap">
	<div class="w-full lg:w-12/12 px-4">
		<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	  >
		<div class="rounded-t bg-white mb-0 px-6 py-6">
		  <div class="text-center flex justify-between">
			<h6 class="text-blueGray-700 text-xl font-bold">門市資訊</h6>

			{#if mode === 'STORE'}
			<button
				class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
				type="button" on:click={save}
			><i class="fas fa-save"></i>&nbsp;儲存
			</button>
			{/if}

		  </div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
		  <form>
			<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
				循環杯門市資訊
			</h6>
			<div class="flex flex-wrap">
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-id"
				  >
				  門市店號
				  </label>
				  <input
					id="grid-id"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.id}
					
				  />
				  {#if isNullId}
				  <div class="text-red-400 mb-3 font-bold">
					<small>門市店號不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-name"
				  >
				  門市名稱
				  </label>
				  <input
					id="grid-name"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.name}
				  />
				  {#if isNullName}
				  <div class="text-red-400 mb-3 font-bold">
					<small>門市名稱不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-bu"
				  >
				  通路別
				  </label>
				  <BusinessUnitSelector bu={store.bu} readonly={isBUreadonly} {display711} on:select={e => onSelectBU(e.detail)}></BusinessUnitSelector>
				  <input type="hidden" id="grid-bu" bind:value={store.bu}/> 
				  {#if isNullBU}
				  <div class="text-red-400 mb-3 font-bold">
					<small>不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-city"
				  >
				  縣市
				  </label>
				  <StoreCitySelector city={store.city} on:select={e => onSelectCity(e.detail)}></StoreCitySelector>
				  <input type="hidden" id="grid-city" bind:value={store.city}/> 
				  {#if isNullCity}
				  <div class="text-red-400 mb-3 font-bold">
					<small>不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-area"
				  >
				  鄉鎮市區
				  </label>
				  <input
					id="grid-area"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.area}
				  />
				  {#if isNullArea}
				  <div class="text-red-400 mb-3 font-bold">
					<small>不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-address"
				  >
				  地址
				  </label>
				  <input
					id="grid-address"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.address}
				  />
				  {#if isNullAddress}
				  <div class="text-red-400 mb-3 font-bold">
					<small>不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-tel"
				  >
				  電話
				  </label>
				  <input
					id="grid-area"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.tel}
				  />
				  {#if isNullTel}
				  <div class="text-red-400 mb-3 font-bold">
					<small>不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
					<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="sdate">
						開始日期
					</label>
					<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						id="sdate" type="date" bind:value={store.startTime} />
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4" >
				<div class="relative w-full mb-3">
					<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="edate">
						結束日期
					</label>
					<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						id="edate" type="date" bind:value={store.endTime}  />
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  狀態
				  </label>

				  {#if store.isAlive}
				  <button on:click={()=> store.isAlive = false}
				  	class="bg-teal-500 text-white active:bg-orange-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
					<i class="fas fa-check"></i> 啟用
				  </button>
				  {:else}
				  <button on:click={()=> store.isAlive = true}
				  	class="bg-red-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
					<i class="fas fa-ban"></i> 停用
				  </button>
				  {/if}
				</div>
			  </div>

			  {#if (store.bu === 0)}
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-zone"
				  >
				  區課
				  </label>
				  <input
					id="grid-zone"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.zone}
				  />
				</div>
			  </div>
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-districtType"
				  >
				  商圈類型
				  </label>
				  <input
					id="grid-districtType"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={store.districtType}
				  />
				</div>
			  </div>
			  {/if}
			</div>

			{#if mode === 'QRCODE'}
			<hr class="mt-6 border-b-1 border-blueGray-300" />

			<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
			  服務識別碼
			</h6>

			<div class="w-full mb-12 px-4">
				<ResultTable {color} {columns} {results} title={''} on:add={e => add()}/>
			</div>
			{/if}

		  </form>
		</div>
	  </div>
	  
	</div>
  </div>
  
{/if}