<script>
    import { onMount } from 'svelte';
	import { apiConfig, checkRole } from '../../store/admin-store' 

	let mode = location.href.indexOf('store-user') >= 0 ? 'STORE' : location.href.indexOf('bu-user') >= 0 ? 'BU' : 'SYSTEM'

	export let id
	export let account = { id: '', name: '', secret: '', role:  (mode === 'BU' ? 'bu_user' : 'reader'), email: '',  isAlive: false }

	let secretIcon = 'fa-eye-slash'
	let secretInput = 'password'
	let isNullId = false
	let isNullName = false
	let isNullEmail = false

	let roles = account.role.split(',')

	onMount(()=> {
		if(id === '' || id === undefined) { return false }

		fetch(`${$apiConfig.apiUrl}/account/list?code=${$apiConfig.apiKey}&name=${id}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				account = d.info[0]
				roles = account.role.split(',')
			} 
		})
		.catch(e => {
			console.log(e)
		})
	})
	
	$:console.log('account', account)
	$:console.log('roles', roles)

	/**
	 * 帳號密碼設定規則
	 * ==============
		1. 新建帳號：自動產生8碼英文數字 <初次登入需重設密碼>
		2. 管理員重置密碼：自動產生8碼英文數字 <初次登入需重設密碼>
		3. 使用者自行重設密碼
			時機：
			(1) 密碼重置後初次登入
			(2) 忘記密碼：輸入email及帳號，系統主動寄發密碼修改信
	*/

	/**
	 * 儲存變更帳號資訊
	 */
	function save() {
		isNullId = account.id === ''
		isNullName = account.name === ''
		isNullEmail = account.email === ''

		if(isNullId || isNullName || isNullEmail) { return false }

		let param = account
		fetch(`${$apiConfig.apiUrl}/account?code=${$apiConfig.apiKey}`, {
			method: 'POST',
			body:JSON.stringify(param)
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				window.location.href = `/admin/${ mode === 'STORE' ? 'store' : mode === 'BU' ? 'bu': 'sys'}-user`
			} else {
				alert(d.returnMessage)
			}
		})
	}

	/**
	 * 重置臨時密碼
	 */
	function resetSecret() {
		let param = account
		fetch(`${$apiConfig.apiUrl}/account/secret?code=${$apiConfig.apiKey}`, {
			method: 'POST',
			body:JSON.stringify(param)
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				location.href = location.href
			} 
		})
	}

	function toggleComfirmModal() {
		if(confirm("即將進行重置密碼，確定要繼續？") === true) {
			resetSecret()
		}
	}

	function toggleSecret() {
		secretInput = secretInput === 'password' ? 'text' :  'password' 
		secretIcon = secretInput === 'password' ? 'fa-eye-slash' : 'fa-eye'
	}

	function toggleRole() {
		if(mode === 'SYSTEM') { account.role = account.role === 'sys_admin' ? 'reader' : account.role === 'reader' ? 'sbk_user' : 'sys_admin' }
		if(mode === 'BU') { account.role = account.role === 'bu_admin' ? 'bu_user' : 'bu_admin' }
		roles = account.role.split(',')
	}
	
</script>

{#if (account !== undefined)}
  <div class="flex flex-wrap">
	<div class="w-full lg:w-12/12 px-4">
		<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	  >
		<div class="rounded-t bg-white mb-0 px-6 py-6">
		  <div class="text-center flex justify-between">
			<h6 class="text-blueGray-700 text-xl font-bold">帳號資訊</h6>
			<button
				class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
				type="button" on:click={save}
			><i class="fas fa-save"></i>&nbsp;儲存
			</button>
		  </div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
		  <form>
			<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
				帳號資訊
			</h6>
			<div class="flex flex-wrap">
			  <div class="w-full lg:w-3/12 px-4">
				 <div class="relative w-full mb-3">
					<label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-last-name"
					>
						帳號
					</label>
					<input
						id="grid-last-name"
						type="text"
						class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						bind:value={account.id}
					/>
					{#if isNullId}
					<div class="text-red-400 mb-3 font-bold">
						<small>帳號不得空白</small>
					</div>
					{/if}
				</div>
			  </div>
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-username"
				  >
				  顯示名稱
				  </label>
				  <input
					id="grid-username"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={account.name}
				  />
				  {#if isNullName}
				  <div class="text-red-400 mb-3 font-bold">
					<small>顯示名稱不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  狀態
				  </label>

				  {#if account.isAlive}
				  <button on:click={()=> account.isAlive = false}
				  	class="bg-teal-500 text-white active:bg-orange-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
					<i class="fas fa-check"></i> 啟用
				  </button>
				  {:else}
				  <button on:click={()=> account.isAlive = true}
				  	class="bg-red-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
					<i class="fas fa-ban"></i> 停用
				  </button>
				  {/if}
				</div>
			  </div>

			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  Email
				  </label>
				  <input
					id="grid-email"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={account.email}
				  />
				{#if isNullEmail}
				  <div class="text-red-400 mb-3 font-bold">
					<small>Email不得空白</small>
				  </div>
				{/if}
				</div>
			  </div>

			  {#if mode === 'SYSTEM'}
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  角色
				  </label>
				  
				  {#if checkRole(roles,'sys_admin')}
					<button on:click={toggleRole}
						class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
						type="button">
						<i class="fas fa-wrench"></i> 系統管理員
					</button>
				  {/if}

				  {#if checkRole(roles,'reader')}
					<button on:click={toggleRole}
						class="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
						type="button">
						<i class="fas fa-user"></i> 讀者
					</button>
				  {/if}

				  {#if checkRole(roles,'sbk_user')}
					<button on:click={toggleRole}
						class="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
						type="button">
						<i class="fas fa-coffee"></i> 星巴克人員
					</button>
				  {/if}
				</div>
			  </div>
			  {/if}

			  {#if mode === 'BU'}
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  角色
				  </label>
				  
				  {#if checkRole(roles,'bu_admin')}
					<button on:click={toggleRole}
						class="bg-orange-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
						type="button">
						<i class="fas fa-wrench"></i> 通路管理員
					</button>
				  {/if}

				  {#if checkRole(roles,'bu_user')}
					<button on:click={toggleRole}
						class="bg-teal-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
						type="button">
						<i class="fas fa-user"></i> 通路人員
					</button>
				  {/if}
				</div>
			  </div>
			  {/if}
			  
			  {#if account.lastResetTime !== undefined}
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				    {account.secret === '' ? '重設':'臨時'}密碼
				  </label>
				  
				  <div class="relative flex w-full flex-wrap items-stretch mb-3">
					{#if account.secret === ''}
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={toggleComfirmModal}
					><i class="fas fa-key"></i>&nbsp;產生臨時密碼
					</button>
					{:else}
					<input
						id="grid-last-name"
						type="{secretInput}" 
						class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						value="{account.secret}"
						disabled
					/>				
					<span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
					  <i class="fas {secretIcon}" on:click={toggleSecret}></i>
					</span>
					{/if}
				  </div>

				  {#if account.lastResetTime !== '1753-01-01T00:00:00'}
				  <div class="text-blueGray-500 mb-3">
					<small> 最後一次重設時間 <i class="fas fa-clock" on:click={toggleSecret}></i> {account.lastResetTime}</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  {/if}
			</div>
		  </form>
		</div>
	  </div>
	</div>
  </div>

{/if}