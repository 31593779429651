<script>
    import { onMount } from 'svelte';
	import { apiConfig, viewInfo } from '../../store/admin-store' 
    import OrderDetailReturn from './OrderDetail_Return.svelte';
    import OrderDetailCpmobi from './OrderDetail_CPMOBI.svelte';
    import OrderDetailBu from './OrderDetail_BU.svelte';
    import OrderDetailWallet from './OrderDetail_Wallet.svelte';

	export let id

	let order

	onMount(()=> {
		fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&id=${id}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					order = d.info[0]
				} 
			})
			.catch(e => {
				console.log(e)
			})

	})
	
	$:console.log('id', id)
</script>

{#if (order !== undefined)}
  <div class="flex flex-wrap">
	<div class="w-full lg:w-12/12 px-4">
		<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	  >
		<div class="rounded-t bg-white mb-0 px-6 py-6">
		  <div class="text-center flex justify-between">
			<h6 class="text-blueGray-700 text-xl font-bold">訂單資訊</h6>
		  </div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
		  <form>
			<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
				循環杯租借資訊
			</h6>
			<div class="flex flex-wrap">
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-username"
				  >
				  訂單編號
				  </label>
				  <input
					id="grid-username"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					value="{order.id}"
				  />
				</div>
			  </div>
			  <div class="w-full lg:w-6/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  會員編號	<i class="fas fa-search" on:click={()=> location.href = `/admin/order?gid=${order.gid}`}></i>
				  </label>
				  <input
					id="grid-email"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					value="{order.gid}"
				  />
				</div>
			  </div>
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-last-name"
				  >
				  	支付類型
				  </label>
				  <input
					id="grid-last-name"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					value="{(order.payType === 3 ? '門市支付' + (order.useWallet ? ' (使用零錢包退款)' : '') : order.payType === 1 ? '線上支付-OpenPoint' : '線上支付-iCashPay' )}"
				  />
				</div>
			  </div>
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-last-name"
				  >
				  	押金
				  </label>
				  <input
					id="grid-last-name"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					value="{order.deposit}"
				  />
				</div>
			  </div>
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-first-name"
				  >
				  	訂單狀態
				  </label>
				  <input
					id="grid-first-name"
					type="text"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					value="{order.orderStatus} {order.refundStatus === '' ? '' : '(' + order.refundStatus +')' }"
				  />
				</div>
			  </div>

			</div>

			{#if (order.returnInfo.length > 0)}
				<OrderDetailReturn {order}></OrderDetailReturn>
			{/if}

			{#if (order.cpmobiPayLog.length > 0)}
				<OrderDetailCpmobi {order}></OrderDetailCpmobi>
			{/if}

			{#if (order.buTradeLog.length > 0)}
				<OrderDetailBu {order}></OrderDetailBu>
			{/if}

			{#if (order.opWalletRefundLog.length > 0)}
				<OrderDetailWallet {order}></OrderDetailWallet>
			{/if}
		  </form>
		</div>
	  </div>
	</div>
  </div>
{/if}