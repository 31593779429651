<script>
    import { onMount } from 'svelte';
	import { apiConfig } from '../../store/admin-store' 

	export let id
	
	let bu = { id: -1, name: '', provider: '2', isAlive: true }
	let isNullName = false

	// $:console.log(bu)

	onMount(()=> {
		if(id === '' || id === undefined) { return false }

		fetch(`${$apiConfig.apiUrl}/bu/list?code=${$apiConfig.apiKey}&bu=${id}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					if(id === undefined){ 
						bu.id = d.info.length
						return false
					 }

					bu = d.info[0]
					bu.provider = String(bu.provider) 
				} 
			})
			.catch(e => {
				console.log(e)
			})
	})

	/**
	 * 儲存變更通路資訊
	 */
	 function save() {
		isNullName = bu.name === ''
		if(isNullName) { return false }

		let param = bu
		fetch(`${$apiConfig.apiUrl}/bu?code=${$apiConfig.apiKey}`, {
			method: 'POST',
			body:JSON.stringify(param)
		})
		.then(response => response.json())
		.then(d => {
			console.log(d)
			if(d.returnCode === '0000') {
				window.location.href = '/admin/bu-info'
			} else {
				alert(d.returnMessage)
			}
		})
	}

	$:console.log('id', id)
</script>

{#if (bu !== undefined)}
  <div class="flex flex-wrap">
	<div class="w-full lg:w-12/12 px-4">
		<div
		class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
	  >
		<div class="rounded-t bg-white mb-0 px-6 py-6">
		  <div class="text-center flex justify-between">
			<h6 class="text-blueGray-700 text-xl font-bold">通路資訊</h6>
			<button
				class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
				type="button" on:click={save}
			><i class="fas fa-save"></i>&nbsp;儲存
			</button>
		  </div>
		</div>
		<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
		  <form>
			<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
				循環杯通路資訊
			</h6>
			<div class="flex flex-wrap">
			  <input type="hidden" bind:value={bu.id}>
			  <div class="w-full lg:w-4/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-name"
				  >
				  通路名稱
				  </label>
				  <input
					id="grid-name"
					class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					bind:value={bu.name}
				  />
				  {#if isNullName}
				  <div class="text-red-400 mb-3 font-bold">
					<small>通路名稱不得空白</small>
				  </div>
				  {/if}
				</div>
			  </div>
			  <div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
					<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
						for="grid-role">
						服務提供者
					</label>
					<select 
						class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						bind:value={bu.provider}>
						<option value="0">7-11超商iECO</option>
						<option value="1">星巴克</option>
						<option value="2">統一相關企業通路</option>
					</select>
				</div>
			</div>
			<div class="w-full lg:w-3/12 px-4">
				<div class="relative w-full mb-3">
				  <label
					class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
					for="grid-email"
				  >
				  狀態
				  </label>

				  {#if bu.isAlive}
				  <button on:click={()=> bu.isAlive = false}
				  	class="bg-teal-500 text-white active:bg-orange-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
					<i class="fas fa-check"></i> 啟用
				  </button>
				  {:else}
				  <button on:click={()=> bu.isAlive = true}
				  	class="bg-red-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
					<i class="fas fa-ban"></i> 停用
				  </button>
				  {/if}
				</div>
			  </div>

		  </form>
		</div>
	  </div>
	  
	</div>
  </div>
  
{/if}