<script>
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

    export let city
	export let readonly = false

	let allCity = [
		'台北市','新北市','基隆市','新竹市','桃園市','新竹縣','宜蘭縣',
		'台中市','苗栗縣','彰化縣','南投縣','雲林縣',
		'高雄市','台南市','嘉義市','嘉義縣','屏東縣','澎湖縣',
		'花蓮縣','台東縣',
		'金門縣','連江縣',]

	$:console.log('city', city)

	function selectCity() {
		dispatch('select', city);
	}
</script>
<select 
	class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
	bind:value={city} on:change="{selectCity}">
	{#if readonly === false}
		<option></option>
	{/if}
	{#each allCity as item}
		<option value={item} selected={(city === item ? 'selected':'')}>
			{item}
		</option>
	{/each}
</select>