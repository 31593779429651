<script>
  import { onMount } from 'svelte';
  
  export let text;
  export let qrbox; 
  export let id = 'qrcode'
	
  let qrcode;
			
	onMount(() => {

		let script = document.createElement('script');
		script.src = "https://recyclecupadminstorage.blob.core.windows.net/script-container/qrcode.min.js"
		document.head.append(script);
		
		script.onload = function() {
			qrcode = new QRCode(id, {
				text: text,
				width: qrbox,
				height: qrbox,
				colorDark : "#000000",
				colorLight : "#ffffff",
				correctLevel : QRCode.CorrectLevel.H
			});		
			
		};				
	  
	});
</script>

<div id="{id}"></div>