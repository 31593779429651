<script>
	import { onMount } from 'svelte';
	import { apiConfig, viewInfo, checkRole } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte"
	import BusinessUnitSelector from './BusinessUnitSelector.svelte'

	let mode = location.href.indexOf('store-qrcode') >= 0 ? 'QRCODE' : 
		location.href.indexOf('stock') >= 0 ? 'STOCK' : 'STORE'

	$viewInfo.funcName = mode === 'STORE' ? '門市管理' : mode === 'STOCK' ? '門市庫存管理' : '門市服務識別碼'

	let color = 'light'
	let columns = ['店號', '門市名稱', '還杯方式', '地址', '狀態', '開始日期', '結束日期']
	if(mode === 'QRCODE') { columns.push('__VIEW__') }
	if(mode === 'STORE') { columns.push('__EDIT_ADD__') }
		
    let results = []
	
	const urlParams = new URLSearchParams(window.location.search)
    let bu = urlParams.get('bu') ?? '0'
	let allBu = []
	let returnType = ''
	let name = ''
	let address = ''

	onMount(()=> {
		fetch(`${$apiConfig.apiUrl}/bu/list?code=${$apiConfig.apiKey}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					allBu = d.info					
				} 
			})
			.catch(e => {
				console.log(e)
			})
	})

	/**
	 * 查詢門市
	 */
	function query() {

		var url = `${$apiConfig.apiUrl}/store/list?code=${$apiConfig.apiKey}&bu=${bu}`

		fetch(url , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					results = []

					var stores = returnType === '還杯機' ?
						 d.info.filter(el=> (el.hasMachine)) : returnType === '櫃台還杯' ? 
						 d.info.filter(el=> (el.hasPOS)) :returnType === '其他' ? 
						 d.info.filter(el=> (el.hasMachine === false && el.hasPOS === false)) : d.info

					if(name !== '') {
						stores = stores.filter(el=> (el.id === name|| el.name.indexOf(name) >= 0))
					}
					if(address !== '') {
						stores = stores.filter(el=> (el.city + el.area + el.address).indexOf(address) >= 0)
					}

					stores.forEach(function(value, index, array){
						let result = []
						result.push(array[index].id)
						result.push(array[index].name)
						result.push(array[index].hasMachine ? '還杯機#teal' : array[index].hasPOS ? '櫃台還杯#orange' : '其他#blueGray')
						result.push(array[index].city + array[index].area + array[index].address)
						result.push(array[index].isAlive ? '啟用#teal' : '停用#red')
						result.push(array[index].startTime.split('T')[0])
						result.push(array[index].endTime.split('T')[0])
						if(mode !== 'STOCK') {
							result.push(`/admin/store-${mode === 'QRCODE' ? 'qrcode': 'info'}/${array[index].bu}/${array[index].id}`)
						}

						results.push(result)
					});

					results = results
				} 
		})
		.catch(e => {
			console.log(e)
		})
	}

	/**
	 * 新增門市
	 */
	function add() {
		location.href = '/admin/store-info-new'
	}	

	/**
	 * 根據BusinessUnitSelector所回傳BU重新查詢
	 * @param item
	 */
	 function onSelectBU(item) {
        console.log('item changed', item);
		bu = item
		query()
	}
</script>

<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">查詢條件</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={query}
					>  <i class="fas fa-search" ></i> 
						查詢
					</button>
				</div>
			</div>
			<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
				<form>
					<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
						選擇查詢方式
					</h6>
					<div class="flex flex-wrap">
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									通路別
								</label>
								<BusinessUnitSelector on:select={e => onSelectBU(e.detail)}></BusinessUnitSelector>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									還杯方式
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={returnType} on:change="{query}">
									<option></option>
									<option value="還杯機">還杯機</option>
									<option value="櫃台還杯">櫃台還杯</option>
									<option value="其他">其他</option>
								</select>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-name">
									店號/店名
								</label>
								<input id="grid-address" type="text"
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={name}/>
							</div>
						</div>
						<div class="w-full lg:w-3/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-address">
									地址
								</label>
								<input id="grid-address" type="text"
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={address}/>
							</div>
						</div>
					</div>
				</form>
			</div>
			</div>
	  </div>
	<div class="w-full mb-12 px-4">
		<ResultTable {color} {columns} {results} on:add={e => add()}/>
	</div>
</div>