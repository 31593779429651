<script>
	import { apiConfig, viewInfo, userInfo } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte";

	$viewInfo.funcName = 'LINE推播服務'

	let color = 'light'
	let columns = ['群組編號', '群組類型', '群組名稱', '連動狀態', '更新時間', '__DELETE__']
    let results = []

	$:console.log('window', window)

	let registers = []

	// 取得門市所有連動群組
	fetch(`${$apiConfig.apiUrl}/notify/register?code=${$apiConfig.apiKey}&storeId=${$userInfo.id}`, {
		method: 'GET'
	})
	.then(response => response.json())
	.then(d => {
		// console.log(d)
		if(d.returnCode === '0000') {
			registers = d.info.registers.filter(el=> el.status !== 0)

			registers.forEach(function(value, index, array){
				let result = []
				result.push(array[index].id)
				result.push(array[index].targetType)
				result.push(array[index].target)
				result.push(array[index].status === 1 || array[index].status === 2 ? '連動中' : array[index].status === 3 ? '已解除': array[index].status)
				result.push(array[index].updateTime)
				result.push(array[index].status === 3 ? '' : array[index].id)
				results.push(result)
			});

			results = results
		} 
	})
	.catch(e => {
		console.log(e)
	})

	/**
	 * 開啟LINE Notify授權端點連結
	 */
	 function redirectAuthUrl() {
		var successUrl = `${window.location.protocol}//${window.location.host}/admin/notify`
		console.log('successUrl', successUrl)

		fetch(`${$apiConfig.apiUrl}/notify/auth?code=${$apiConfig.apiKey}&storeId=${$userInfo.id}&redirectUrl=${successUrl}`, {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					location.href = d.info.authorizeUrl
				} 
		})
		.catch(e => {
			console.log(e)
		});
	}

	/**
	 * 解除連動
	 */
	function revolke(id) {
		console.log('revolke: ', id)
		var revokeData = registers.filter(el=> el.id === id)[0]
		console.log('revokeData= ', revokeData)

		fetch(`${$apiConfig.apiUrl}/notify/revoke?code=${$apiConfig.apiKey}&registerId=${revokeData.id}&storeId=${revokeData.groupId}`, {
			method: 'POST'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					location.href = '/admin/notify'
				} 
		})
		.catch(e => {
				console.log(e)
		})
	}

</script>


<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">LINE推播訊息群組</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={redirectAuthUrl}
					>
					新增推播訊息群組
					</button>
				</div>
			</div>

			<ResultTable {color} {columns} {results} title={''} on:delete={e => revolke(e.detail)}/>
		</div>
	  </div>
</div>