<script>
  import { Router, Route } from "svelte-routing";
  import { Redirect } from '../components';


  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";
  import Settings from "views/admin/Settings.svelte";
  import Tables from "views/admin/Tables.svelte";
  import Maps from "views/admin/Maps.svelte";
  import Order from "views/admin/Order.svelte";
  import OrderDetail from "views/admin/OrderDetail.svelte";
  import ReturnCup from "views/admin/ReturnCup.svelte";
  import LineNotify from "views/admin/LineNotify.svelte";
  import Account from "views/admin/Account.svelte";
  import AccountDetail from "views/admin/AccountDetail.svelte";
  import AccountResetKey from "views/admin/AccountResetKey.svelte";
  import Store from "views/admin/Store.svelte";
  import StoreDetail from "views/admin/StoreDetail.svelte";
  import BusinessUnit from "views/admin/BusinessUnit.svelte";
  import BusinessUnitDetail from "views/admin/BusinessUnitDetail.svelte";
  import StoreQRCode from "views/admin/StoreQRCode.svelte";
  import PageNotFound from "views/admin/404.svelte";
  import Stock from "views/admin/Stock.svelte";
  import StoreFiles from "views/admin/StoreFiles.svelte";

  
  export let location;
</script>

<div>
  <Sidebar location={location}/>
  <div class="relative md:ml-64 bg-blueGray-100">
    <AdminNavbar />
    <HeaderStats />
    <div class="px-4 md:px-10 mx-auto w-full -m-24">
      <Router url="admin">
        <Route path="dashboard" component="{Dashboard}" />
        <Route path="settings" component="{Settings}" />
        <Route path="tables" component="{Tables}" />
        <Route path="maps" component="{Maps}" />
        <Route path="order" component="{Order}" />
        <Route path="order/:id" component="{OrderDetail}" />
        <Route path="return-cup" component="{ReturnCup}" />
        <Route path="notify" component="{LineNotify}" />
        <Route path="sys-user" component="{Account}" />
        <Route path="sys-user/:id" component="{AccountDetail}" />
        <Route path="sys-user-new" component="{AccountDetail}" />
        <Route path="reset-key" component="{AccountResetKey}" />
        <Route path="store-info" component="{Store}" />
        <Route path="store-info/:bu/:id" component="{StoreDetail}" />
        <Route path="store-info-new" component="{StoreDetail}" />
        <Route path="store-qrcode" component="{Store}" />
        <Route path="store-qrcode/:bu/:id" component="{StoreDetail}" />
        <Route path="store-qrcode-preview/:bu/:id" component="{StoreQRCode}" />
        <Route path="store-user" component="{Account}" />
        <Route path="store-user/:id" component="{AccountDetail}" />
        <Route path="store-user-new" component="{AccountDetail}" />
        <Route path="store-files" component="{StoreFiles}" />
        <Route path="bu-info" component="{BusinessUnit}" />
        <Route path="bu-info/:id" component="{BusinessUnitDetail}" />
        <Route path="bu-info-new" component="{BusinessUnitDetail}" />
        <Route path="bu-user" component="{Account}" />
        <Route path="bu-user/:id" component="{AccountDetail}" />
        <Route path="bu-user-new" component="{AccountDetail}" />
        <Route path="stock" component="{Stock}" />
        <Route path="404" component="{PageNotFound}" />
        <Route path="**">
          <Redirect path="/admin/404" />
        </Route>
      </Router>
      <FooterAdmin />
    </div>
  </div>
</div>
