<script>
	import { apiConfig, viewInfo } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte";

	$viewInfo.funcName = '借還杯紀錄查詢'

	let color = 'light'
	let columns = ['訂單編號', '支付方式', '訂單狀態', '租借/取杯/歸還', '租借時間', '最後歸還時間', '__VIEW__']
    let results = []
	
	const urlParams = new URLSearchParams(window.location.search)
    let id = urlParams.get('id') ?? ''
	let gid = urlParams.get('gid') ??''
	let tel = urlParams.get('tel') ??''
	let sdate = new Date().toLocaleDateString()
	let edate = new Date().toLocaleDateString()

	let radioGroup = gid !== '' ? 'gid' : 'orderId' ;
	let condition = {}

	$:console.log('window', window)
	
	/**
	 * 查詢訂單
	 */
	function queryOrder() {

		condition = { radioGroup: radioGroup, gid: gid, orderId: id, phone: tel, sdate: sdate, edate: edate }

		console.log('#### condition=', condition)

		var url = `${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&id=${condition.orderId}`
		if(condition.radioGroup === 'phone') { url =  `${$apiConfig.apiUrl}/customer/order?code=${$apiConfig.apiKey}&tel=${condition.phone}&sdate=${condition.sdate}&edate=${condition.edate}` }
		if(condition.radioGroup === 'gid') { url += `&gid=${condition.gid}` }
		
		fetch(url , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					results = []

					d.info.forEach(function(value, index, array){
						let result = []
						result.push(array[index].id)
						result.push(array[index].payType === 3 ? '現金支付#emerald' : '線上支付#red')
						result.push(array[index].orderStatusShort + (array[index].refundStatus === '' ? '' : ' (' + array[index].refundStatus +')'))
						result.push(array[index].totalCount + " / " + array[index].redeemCount + " / " + array[index].returnCount )
						result.push(array[index].rentTimeStr)
						result.push(array[index].lastReturnTime)
						result.push(`/admin/order/${array[index].id}`)
						results.push(result)
					});

					results = results
				} 
		})
		.catch(e => {
			console.log(e)
		})
	}

	queryOrder()

	$:console.log('radioGroup' , radioGroup)

</script>


<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">查詢條件</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={queryOrder}
					> <i class="fas fa-search" ></i> 
						查詢
					</button>
				</div>
			</div>
			<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
				<form>
					<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
						選擇查詢方式
					</h6>
					<div class="flex flex-wrap">
						<ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
							<li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
								<div class="flex items-center pl-3">
									<input id="horizontal-list-radio-license" type="radio" 
										bind:group={radioGroup} name="radioGroup" value={'orderId'}
										class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
									<label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">依訂單編號</label>
								</div>
								<div class="w-full lg:w-12/12 px-4" style="display:{(radioGroup === 'orderId' ? 'block' : 'none')}">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="orderId">
											訂單編號
										</label>
										<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											id="orderId" type="text" bind:value={id}/>
									</div>
								</div>
							</li>
							<li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
								<div class="flex items-center pl-3">
									<input id="horizontal-list-radio-id" type="radio" 
											bind:group={radioGroup} name="radioGroup" value={'gid'}
											class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
									<label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">依會員編號</label>
								</div>
								<div class="w-full lg:w-12/12 px-4" style="display:{(radioGroup === 'gid' ? 'block' : 'none')}">
									<div class="relative w-full mb-3">
										<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="gid">
											會員編號
										</label>
										<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											id="gid" type="text" bind:value={gid} />
									</div>
								</div>
							</li>
							<li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
								<div class="flex items-center pl-3">
									<input id="horizontal-list-radio-millitary" type="radio" 
										bind:group={radioGroup} name="radioGroup" value={'phone'}
										class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
									<label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">依手機號碼</label>
								</div>

								<div class="flex flex-wrap">
									<div class="w-full lg:w-4/12 px-4" style="display:{(radioGroup === 'phone' ? 'block' : 'none')}">
										<div class="relative w-full mb-3">
											<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="sdate">
											開始日期
											</label>
											<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
												id="sdate" type="date" bind:value={sdate} />
										</div>
									</div>
									<div class="w-full lg:w-4/12 px-4" style="display:{(radioGroup === 'phone' ? 'block' : 'none')}">
										<div class="relative w-full mb-3">
											<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="edate">
												結束日期
											</label>
											<input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
												id="edate" type="date" bind:value={edate}  />
										</div>
									</div>
									<div class="w-full lg:w-4/12 px-4" style="display:{(radioGroup === 'phone' ? 'block' : 'none')}">
										<div class="relative w-full mb-3">
											<label
											class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
											for="grid-city"
											>
											手機號碼
											</label>
											<input
											id="grid-city"
											type="text"
											class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											bind:value={tel} 
											/>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</form>
			</div>
			</div>
	  </div>
	<div class="w-full mb-12 px-4">
		<ResultTable {color} {columns} {results} />
	</div>
</div>