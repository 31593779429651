<script>
  import { apiConfig, viewInfo, userInfo, checkRole } from '../../store/admin-store' 
  import { onMount } from "svelte";

  // core components
  import WeekBorrowLineChart from '../../components/Cards/WeekBorrowLineChart.svelte';

  $viewInfo.funcName = ''

  let is_sbk = true
  let borrowData = []
  let returnData = []

  // onMount(async () => {
  //   is_sbk = checkRole($userInfo.role,'sbk_user')
  //   if(is_sbk) { return false }

  //   var url = `${$apiConfig.apiUrl}/report/summary?code=${$apiConfig.apiKey}`

	// 	fetch(url , {
	// 		method: 'GET'
	// 	})
	// 	.then(response => response.json())
	// 	.then(d => {
  //       console.log(d)
	// 			if(d.returnCode === '0000') {
	// 				borrowData = d.info.weekBorrow
  //         returnData = d.info.weekReturn
	// 			} 
	// 	})
	// 	.catch(e => {
	// 		console.log(e)
	// 	})
  // })


  $:console.log('userInfo', $userInfo)
  if($userInfo.menu.length === 0) {
    window.location.href = '/auth/login'
  }

</script>

<div style="display: {is_sbk ? 'none' : 'block'};">
  <!-- <div class="flex flex-wrap">
    <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
      <WeekBorrowLineChart {borrowData}/>
    </div>
  </div> -->
  <!-- <div class="flex flex-wrap mt-4">
    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
      <CardPageVisits />
    </div>
    <div class="w-full xl:w-4/12 px-4">
      <CardSocialTraffic />
    </div>
  </div> -->
</div>