<script>
	export let order
	
	let showBuTradeLog = true

</script>
<hr class="mt-6 border-b-1 border-blueGray-300" />
<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
	<i class="fa fa-{showBuTradeLog ? 'minus' : 'plus'}" on:click={()=> showBuTradeLog = !showBuTradeLog}></i>
	跨通路櫃台交易明細 
</h6>

{#if showBuTradeLog}
	{#each order.buTradeLog as item}
		<div class="flex flex-wrap">
			<div class="w-full mb-3">
				{#if item.tradeType === 0}
					<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-lightBlue-600 bg-lightBlue-200 last:mr-0 mr-1">
						取杯
					</span>
				{:else if item.tradeType === 1}
					<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-teal-600 bg-teal-200 last:mr-0 mr-1">
						還杯
					</span>
				{/if}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-amber-600 bg-amber-200 last:mr-0 mr-1">
					{item.count} 杯 
				</span>
			</div>

			<div class="w-8/12 mb-3">	
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					通路名稱： {item.businessUnitName} 
				</label>
				
				{#if item.paidStatus === 1}
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					門市名稱： {item.storeName} （{item.storeId} ）
				</label>
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					掃碼時間： {item.paidTime} 
				</label>
				{/if}
			</div>
		</div>
	{/each} 
{/if}