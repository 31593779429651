<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let items = [];
  export let currentPage = 1
  export let pageSize = 4

  let pageCount = 0
  let pages = []

  // $:console.log('#### pageCount=', pageCount)
  // $:console.log('## currentPage=', currentPage)
  // $:console.log('pages=', pages)

  $:{
    pageCount = Math.ceil(items.length  / pageSize)
    pages = []
    for(let i = 1; i<= pageCount; i++) { 
      if((currentPage < 5 && i <= 10) || 
        (currentPage >= i - 5 && currentPage <= i + 5) || 
        (currentPage > pageCount - 5 && i >= pageCount - 10)) {
        pages.push(i)
      }
    }
    if(currentPage > pageCount) {
      selectPage(1)
    }
  }

  function selectPage(page) {
    dispatch('select', page);
  }

</script>

{#if (pageCount > 1)}
<div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
  <div class="py-2 ">
    <nav class="block">
      <ul class="flex pl-0 rounded list-none flex-wrap">
        {#if currentPage !== 1}
        <li>
          <btn on:click={()=> selectPage(1)}
            class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-white text-blueGray-500">
            <i class="fas fa-chevron-left -ml-px"></i>
            <i class="fas fa-chevron-left -ml-px"></i>
          </btn>
        </li>
        {/if}
        <!-- <li>
          <a href="#pablo" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-white text-blueGray-500">
            <i class="fas fa-chevron-left -ml-px"></i>
          </a>
        </li> -->
        {#each pages as pageNumber}
          <li>
            {#if (pageNumber === currentPage)}
            <btn on:click={()=> selectPage(pageNumber)}
              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 text-white bg-blueGray-600">
              {pageNumber}
            </btn>
            {:else}
            <li>
              <btn on:click={()=> selectPage(pageNumber)}
                class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-white text-blueGray-500">
                {pageNumber}
              </btn>
            </li>
            {/if}
          </li>
        {/each}
        <!-- <li>
          <a href="#pablo" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-white text-blueGray-500">
            <i class="fas fa-chevron-right -mr-px"></i>
          </a>
        </li> -->
        {#if currentPage !== pageCount}
        <li>
          <btn on:click={()=> selectPage(pageCount)}
            class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-white text-blueGray-500">
            <i class="fas fa-chevron-right -mr-px"></i>
            <i class="fas fa-chevron-right -mr-px"></i>
          </btn>
        </li>
        {/if}
      </ul>
    </nav>
  </div>
</div>

{/if}