<script>
	import { apiConfig, viewInfo, checkRole } from '../../store/admin-store' 
	import ResultTable from "components/Cards/ResultTable.svelte";

	let mode = location.href.indexOf('store-user') >= 0 ? 'STORE' : 
		location.href.indexOf('bu-user') >= 0 ? 'BU' : 'SYSTEM'

	$viewInfo.funcName = mode === 'STORE'? '門市帳號管理' : mode === 'BU'? '通路帳號管理' : '帳號權限管理' 

	let color = 'light'
	let columns = [ '帳號','__IMAGE__', '顯示名稱', '角色', '狀態', '建立時間', '更新時間', (mode === 'STORE' ? '__EDIT__' : '__EDIT_ADD__')]
    let results = []
	
	const urlParams = new URLSearchParams(window.location.search)

    let name = urlParams.get('name') ?? ''
	let role = urlParams.get('role') ??''

	let allRoles = mode === 'STORE' ? [ { id: 'store_admin', name: '門市管理員'},{ id: 'store_user', name: '門市人員'} ] :
		mode === 'BU' ? [ { id: 'bu_admin', name: '通路管理員'},{ id: 'bu_user', name: '通路人員'} ] :
	 [ { id: 'sys_admin', name: '系統管理員'},{ id: 'reader', name: '讀者'} ]

	// $:console.log('name', name)
	// $:console.log('role', role)
	
	/**
	 * 查詢帳號
	 */
	function query() {

		var url = `${$apiConfig.apiUrl}/account/list?code=${$apiConfig.apiKey}&name=${name}&role=${role}`

		fetch(url , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				// console.log(d)
				if(d.returnCode === '0000') {
					results = []

					var accounts =
						mode === 'STORE' ? d.info.filter(el=> (el.role === 'store_admin' || el.role === 'store_user')) :
						mode === 'BU' ? d.info.filter(el=> (el.role === 'bu_admin' || el.role === 'bu_user')) :
					 	d.info.filter(el=> (el.role === 'sys_admin' || el.role === 'reader'|| el.role === 'sbk_user'))

					accounts.forEach(function(value, index, array){
						let result = []
						let roles = array[index].role.split(',')
						let user_role = checkRole(roles,'sys_admin') ? '系統管理員#lightBlue':
							checkRole(roles,'reader') ? '讀者#red' : 
							checkRole(roles,'store_admin') ? '門市管理員#emerald' :
							checkRole(roles,'store_user') ? '門市人員#emerald' : 
							checkRole(roles,'bu_admin') ? '通路管理員#orange' :
							checkRole(roles,'bu_user') ? '通路人員#teal' :
							checkRole(roles,'sbk_user') ? '星巴克人員#teal' :''

						result.push(array[index].id)
						result.push(`../assets/img/user-${roles}.png`)
						result.push(array[index].name)
						result.push(user_role)
						result.push(array[index].isAlive ? '啟用#teal' : '停用#red')
						result.push(array[index].createTime)
						result.push(array[index].updateTime)
						result.push(`/admin/${ mode === 'STORE' ? 'store' : mode === 'BU' ? 'bu': 'sys'}-user/${array[index].id}`)
						results.push(result)
					});

					results = results
				} 
		})
		.catch(e => {
			console.log(e)
		})
	}

	/**
	 * 新增帳號
	 */
	function add() {
		location.href = mode === 'STORE' ?  '/admin/store-user-new':  mode === 'BU' ?  '/admin/bu-user-new': '/admin/sys-user-new'
	}

	query()
	
</script>

<div class="flex flex-wrap mt-4">
	<div class="w-full lg:w-12/12 px-4">
		<div
			class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
			>
			<div class="rounded-t bg-white mb-0 px-6 py-6">
				<div class="text-center flex justify-between">
					<h6 class="text-blueGray-700 text-xl font-bold">查詢條件</h6>
					<button
						class="bg-blueGray-700 text-white active:bg-blueGray-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
						type="button" on:click={query}
					>  <i class="fas fa-search" ></i> 
						查詢
					</button>
				</div>
			</div>
			<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
				<form>
					<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
						選擇查詢方式
					</h6>

					<div class="flex flex-wrap">
						<div class="w-full lg:w-6/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-username">
									帳號/名稱
								</label>
								<input id="grid-username" type="text"
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={name}/>
							</div>
						</div>
						<div class="w-full lg:w-6/12 px-4">
							<div class="relative w-full mb-3">
								<label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
									for="grid-role">
									角色
								</label>
								<select 
									class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									bind:value={role} on:change="{query}">
									<option value=""></option>
									{#each allRoles as role}
										<option value={role.id}>
											{role.name}
										</option>
									{/each}
								</select>
							</div>
						</div>
					</div>
				</form>
			</div>
			</div>
	  </div>
	<div class="w-full mb-12 px-4">
		<ResultTable {color} {columns} {results} on:add={e => add()}/>
	</div>
</div>