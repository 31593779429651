<script>
  import { Router, Link, Route } from 'svelte-routing';
  import { PageNotFound, Redirect } from './components';

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Landing from "./views/Landing.svelte";
  import Profile from "./views/Profile.svelte";
  
  export let url = '';
</script>

<Router {url}>
  <div class="cover-container d-flex w-100 h-75 mx-auto flex-column">
    <div class="section columns">
      <main class="column">
        <div>
          <Route path="/" >
            <Redirect path="/admin/dashboard" />
          </Route>
          <Route path="**" component={PageNotFound} />

          <!-- admin layout -->
          <Route path="admin/*admin" component="{Admin}" />
          <Route path="admin">
            <Redirect path="/admin/dashboard" />
          </Route>

          <!-- auth layout -->
          <Route path="auth/*auth" component="{Auth}" />

          <!-- no layout pages -->
          <Route path="landing" component="{Landing}" />
          <Route path="profile" component="{Profile}" />
          
           <!-- line notify page redirect -->
          <Route path="/notify" >
            <Redirect path="/admin/notify" />
          </Route>
        </div>
      </main>  
    </div>
  </div>
</Router>
