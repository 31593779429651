<svelte:head>
  <script src="https://www.google.com/recaptcha/api.js" async defer></script>
</svelte:head>
<script>
  import { link } from "svelte-routing";
  import { userInfo, apiConfig, viewInfo, writeLog } from '../../../src/store/admin-store' 
  import ConfigInitializer from './ConfigInitializer.svelte';

  $viewInfo.appName = 'OPEN iECO後台管理系統'

  const siteKey = "6Lc0k6MpAAAAALqqe8RfJCThDyU_OvPuTFtquKPG";
  let gtoken = ''

  let id = ''
  let secret = ''
  let invalid = false
	let showKey1 = false

  $userInfo = {
      id: '',
      name: '',
      hasKey: false,
      role: [],
      menu: new Array()
  }

  window.onSubmit = function (token){
    console.log(token);
    gtoken = token
  }

  window.onExpired = function (){
    console.log('token expired');
    gtoken = ''
  }

  /**
   * 帳號權限驗證
   */
  function login() {

      let param = {id: id, secret: secret}
      fetch(`${$apiConfig.apiUrl}/authorize?code=${$apiConfig.apiKey}`, {
          method: 'POST',
          body:JSON.stringify(param)
      })
      .then(response => response.json())
      .then(d => {
          console.log(d)
          if(d.returnCode === '0000') {
              var role = d.info.role.split(',')

              /*
                系統角色定義/相關功能權限：
                 1. 系統管理員(sys_admin) : 帳務報表、特約店管理、庫存管理、通路(特約店)管理、門市管理、帳號管理
                 2. 特約店/BU管理員(bu_admin) : 帳務報表、庫存管理、門市管理
                 3. 門市管理員 (store_admin) : 帳務報表
                 4. 門市人員 (store_user) : 推播服務/LINE推播服務
                 5. 讀者 (reader) : 帳務報表
                 6. 星巴克人員 (sbk_user) : 帳務報表(還杯紀錄查詢)
              */

              let menu = new Array()

              if(checkPermission(role, 'store_user') || checkPermission(role, 'store_admin')) {
                menu.push({ node: '推播服務', content :[{ name : 'LINE推播服務', path: '/admin/notify', icon: 'tv'}]})
              }

              if(checkPermission(role, 'sys_admin') || checkPermission(role, 'bu_admin') || 
                checkPermission(role, 'store_admin') || checkPermission(role, 'reader')) {
                menu.push({ node: '帳務報表', content :[{ name : '訂單查詢', path: '/admin/order', icon: 'table'}, { name : '還杯紀錄查詢', path: '/admin/return-cup', icon: 'table'}]})
              }

              if(checkPermission(role, 'sbk_user')) {
                menu.push({ node: '帳務報表', content :[{ name : '還杯紀錄查詢', path: '/admin/return-cup', icon: 'table'}]})
              }

              if(checkPermission(role, 'sys_admin') || checkPermission(role, 'bu_admin') || checkPermission(role, 'bu_user')) {
                menu.push({ node: '庫存管理', content :[{ name : '門市庫存查詢', path: '/admin/stock', icon: 'table'},{ name : '門市資料檔', path: '/admin/store-files', icon: 'table'}]})
              }

              if(checkPermission(role, 'sys_admin') || checkPermission(role, 'bu_admin')) {
                menu.push({ node: '門市管理', content :[{ name : '門市管理', path: '/admin/store-info', icon: 'store'},{ name : '門市帳號管理', path: '/admin/store-user', icon: 'user'}, { name : '識別碼', path: '/admin/store-qrcode', icon: 'qrcode'}]})
              }

              if(checkPermission(role, 'sys_admin')) {
                menu.push({ node: '通路管理', content :[{ name : '通路管理', path: '/admin/bu-info', icon: 'map'}, { name : '通路帳號管理', path: '/admin/bu-user', icon: 'user'}]})
                menu.push({ node: '帳號管理', content :[{ name : '帳號權限管理', path: '/admin/sys-user', icon: 'user'}]})
              }

              $userInfo = {
                  id: d.info.id,
                  name: d.info.name,
                  hasKey: d.info.hasKey,
                  isExpired: d.info.isExpired,
                  role: role,
                  menu: menu
              }

              writeLog($userInfo.id, 'PORTAL', '登入', '', '', $apiConfig.apiUrl, $apiConfig.apiKey)

              window.location.href = '/admin/dashboard'

          } else {
              invalid = true
          }
      })
  }

  function handleKeydown(e) {
    if(e.key === 'Enter') {
      login() 
    }
	}

  function checkPermission(own_role, role_name) {
    return own_role.filter(el => el === role_name).length > 0
  }
</script>

<ConfigInitializer></ConfigInitializer>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <h6 class="text-blueGray-600 text-sm font-bold">
              OPEN iECO後台管理系統
            </h6>
          </div>
          <hr class="mt-6 border-b-1 border-blueGray-300" />
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        
          <form>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-email">
                帳號
              </label>
              <input
                id="grid-email"
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="請輸入帳號"
                bind:value={id} invalid="{invalid}"
                on:keydown={handleKeydown}
              />
            </div>

            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-email">
                密碼
              </label>

             
              {#if showKey1}
                <input
                  id="grid-password"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="請輸入正確的密碼"
                  feedback="帳號或者密碼不正確"
                  bind:value={secret} invalid="{invalid}" 
                  on:keydown={handleKeydown}
                />	
              {:else}
                <input
                  id="grid-password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="請輸入正確的密碼"
                  feedback="帳號或者密碼不正確"
                  bind:value={secret} invalid="{invalid}" 
                  on:keydown={handleKeydown}
                />			
              {/if}
              
              <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                <i class="fas fa-eye{showKey1 ? '' : '-slash'}" on:click={()=> showKey1 = !showKey1}></i>
              </span>
            </div>

            {#if (invalid)}
            <div class="text-red-400 text-center mb-3 font-bold">
              <small>帳號或密碼不正確</small>
            </div>
            {/if}

            <div class="text-red-400 mb-3 font-bold">
              <ol>
                <li class="mb-3">  
                  <small>1.【敬告】為落實個人資料以及公司機密保護，嚴禁翻拍、轉載與洩漏任何資料；接觸相關業務之同仁，應善盡保密責任，如違反，可依法處五年以下有期徒刑、拘役或併科新台幣一百萬以下罰金外，並將依公司獎懲辦法處理，情節嚴重時將予以解雇。若造成公司受有損害，必將依法請求相關損害賠償。</small> 
                </li>
                <li>
                  <small>2.未經公司同意使用他人帳號、提供帳號予他人使用、或使他人使用自己帳號進行操作或拷貝資料等，均違反公司規定，將依公司獎懲辦法懲處。</small>                 
                </li>
              </ol>
            </div>

            <div class="text-center mt-6">
              <div class="g-recaptcha max-w-100-px" data-sitekey="{siteKey}" data-callback="onSubmit" data-expired-callback="onExpired" ></div>
              <br>

              {#if gtoken !== ''}
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button" on:click={login} >
                登入
              </button>
              {/if}
              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
