<script>
	import { createEventDispatcher } from 'svelte';
	import { apiConfig } from '../../store/admin-store' 

	const dispatch = createEventDispatcher();

    export let bu
	export let readonly = false
	export let display711 = true

	let allBu = []

	$:{
		fetch(`${$apiConfig.apiUrl}/bu/list?code=${$apiConfig.apiKey}` , {
			method: 'GET'
		})
		.then(response => response.json())
		.then(d => {
				console.log(d)
				if(d.returnCode === '0000') {
					allBu = readonly ? d.info.filter(el=> el.id === bu) : display711 ? d.info : d.info.filter(el=> el.id > 0)				
				} 
			})
			.catch(e => {
				console.log(e)
			})
	}

	function selectBU() {
		dispatch('select', bu);
	}
</script>
<select 
	class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
	bind:value={bu} on:change="{selectBU}">
	{#if readonly === false}
		<option value="-1"></option>
	{/if}
	{#each allBu as item}
		<option value={item.id} selected={(bu === item.id ? 'selected':'')}>
			{item.name}
		</option>
	{/each}
</select>