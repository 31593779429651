<script>
	export let order
	
	let showCpmobiLog = true
	let showUnpaid = false

</script>
<hr class="mt-6 border-b-1 border-blueGray-300" />

<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
	<i class="fa fa-{showCpmobiLog ? 'minus' : 'plus'}" on:click={()=> showCpmobiLog = !showCpmobiLog}></i>
	超商櫃台交易明細 
	<i class="fa fa-eye{showUnpaid ? '' : '-slash'}" on:click={()=> showUnpaid = !showUnpaid}></i>
</h6>

{#if showCpmobiLog}
	{#each order.cpmobiPayLog as item}
		{#if (item.paidStatus === 1 ||  showUnpaid)}
		<div class="flex flex-wrap">
			<div class="w-full mb-3">
				{#if item.type === 0 || item.type === 1}
					<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-lightBlue-600 bg-lightBlue-200 last:mr-0 mr-1">
						{(item.type === 0 ? '取杯' :'繳費取杯')} 
					</span>
				{:else if item.type === 2}
					<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-red-600 bg-red-200 last:mr-0 mr-1">
						退款
					</span>
				{:else if item.type === 3 || item.type === 4}
					<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-orange-600 bg-orange-200 last:mr-0 mr-1">
						臨櫃還杯
					</span>

					{#if item.type === 4 }
						<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-red-600 bg-red-200 last:mr-0 mr-1">
							退款
						</span>
					{/if}
				{/if}

				{#if (item.amount > 0)}
					<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-amber-600 bg-amber-200 last:mr-0 mr-1">
						{item.amount} 元
					</span>
				{/if}

				{#if item.paidStatus === 0}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-blueGray-600 bg-blueGray-200 last:mr-0 mr-1">
					尚未刷讀
				</span>
				{:else if item.paidStatus === 1}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-teal-600 bg-teal-200 last:mr-0 mr-1">
					刷讀成功
				</span>
				{:else}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-blueGray-600 bg-blueGray-200 last:mr-0 mr-1">
					已取消
				</span>
				{/if}
			</div>

			<div class="w-8/12 mb-3">	
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					條碼名稱： {item.name} 
				</label>
				{#if item.paidStatus === 1}
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					刷讀時間： {item.paidTime} 
				</label>
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					店號： {item.storeId} 
				</label>
				{:else}
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					產生時間： {item.createTime} 
				</label>
				{/if}
			</div>
		</div>
		{/if}
	{/each} 
{/if}