import { writable } from 'svelte/store';
import { localStore } from './localStore'

// 後端API相關參數 
const apiOpt = {
    apiUrl: 'https://recyclecup-function-uat.azurewebsites.net/api',
    apiKey: 'X/1sT1aU5j6URttULpUpQm9JEBzUaHJVK2BPDpLhgv0rMdgnCNapvQ==',
    storageEndpoint: ''
}
const apiConfig = localStore('api-configuration-2', apiOpt)

// 使用者資訊
const userInfoObj = {
    id: '',
    name: '',
    role: [],
    menu: []
}

const userInfo = localStore('user-info', userInfoObj)

const viewInfoObj = {
    appName: '',
    funcName: '',
    queryResult: []
}

const viewInfo = localStore('view-info', viewInfoObj)

export function checkRole(user_roles, role) {
    return user_roles.find(function(item, index, arr){ return item === role})
}

export function writeLog(account, func_id, action, desc, remark, url, key) {
    let param = { accountId: account, functionId: func_id, action: action, description: desc, remark: remark}
    fetch(`${url}/active/log?code=${key}`, {
        method: 'POST',
        body:JSON.stringify(param)
    })
    .then(response => response.json())
    .then(d => {
        console.log(d)
    })
}

export { 
    userInfo, 
    viewInfo,
    apiConfig
};

