<script>
	export let order
	
	let showWalletLog = true

</script>
<hr class="mt-6 border-b-1 border-blueGray-300" />

<h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
	<i class="fa fa-{showWalletLog ? 'minus' : 'plus'}" on:click={()=> showWalletLog = !showWalletLog}></i>
	零錢包退款交易明細 
</h6>
{#if showWalletLog}
	{#each order.opWalletRefundLog as item}
		<div class="flex flex-wrap">
			<div class="w-full mb-3">

				{#if item.refundStatus === 0}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-blueGray-600 bg-blueGray-200 last:mr-0 mr-1">
					尚未退款
				</span>
				{:else if item.refundStatus === -33}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full  text-orange-600 bg-orange-200 last:mr-0 mr-1">
					等待退款
				</span>
				{:else if item.refundStatus === 3}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-teal-600 bg-teal-200 last:mr-0 mr-1">
					退款成功
				</span>
				{:else}
				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full  text-red-600 bg-red-200 last:mr-0 mr-1">
					退款失敗
				</span>
				{/if}

				<span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-amber-600 bg-amber-200 last:mr-0 mr-1">
					{item.refundAmount} 元
				</span>
			</div>

			<div class="w-8/12 mb-3">	
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					訂單編號： {item.id} 
				</label>
				
				{#if item.returnCode !== ""}
				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					回傳結果： {item.returnCode} 

					{#if item.returnMessage !== ""}
						({item.returnMessage})
					{/if}
				</label>
				{/if}

				<label
					class="block uppercase text-blueGray-600 text-xs mb-2"
					for="grid-first-name"
				> 
					更新時間： {item.updateTime} 
				</label>
			</div>
		</div>
	{/each}
{/if} 